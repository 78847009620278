import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '~/components/Layout/Layout';
import Posts from '~/components/Posts/Posts';

const TagIndex = ({ pageContext: { posts, tag, path }, data }) => {
  const { social, pages, calendly, title } = data.site.siteMetadata;
  const meta = pages.blog;

  const postCounts = posts.length;
  const label = postCounts === 1 ? 'post' : 'posts';
  const tagName = tag.charAt(0).toUpperCase() + tag.slice(1);

  return (
    <Layout
      path={path}
      meta={meta}
      title={title}
      socials={social}
      calendly={calendly}
    >
      <div className="page">
        <h1 className="page-title">
          {postCounts} {label} tagged with {tagName}
        </h1>

        <Posts posts={posts} />
      </div>
    </Layout>
  );
};

TagIndex.propTypes = {
  pageContext: PropTypes.object,
  data: PropTypes.object
};

export default TagIndex;

export const pageQuery = graphql`
  query TagIndex {
    site {
      siteMetadata {
        title
        calendly {
          kubeflow {
            url
            googleAnalytics {
              category
              action
              label
            }
          }
        }
        pages {
          blog {
            title
            description
            keywords
            googleSiteVerification
          }
        }
        social {
          twitter
          github
          linkedin
          email
          feed
        }
      }
    }
  }
`;
